import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import styles from './Footer.module.css'

class Footer extends Component {
  render(){
    return(
      <Row className={styles.footer}>
        <Col md={4} className={`${styles.footerBox} rrss`}>
          <h5>Redes Sociales</h5>
          <Row>
            <Col>
              <a href="https://www.linkedin.com/in/felipetorrejont/">LinkedIn</a>
            </Col>
          </Row>
          <Row>
            <Col>
              <a href="https://www.github.com/fip3torrejon">Github</a>
            </Col>
          </Row>
        </Col>
        <Col md={6} className={`${styles.footerBox} contacto`}>
          <h5>Contacto</h5>
          <Row>
            <Col xs={12}>EMail: felipe@703.cl</Col>
            <Col xs={12}>EMail: felipetorrejon@live.cl</Col>
            <Col xs={12}>Teléfono: (+56) 979044949</Col>
            <Col xs={12}>Ubicación: Viña del Mar, Chile</Col>
          </Row>
        </Col>
      </Row>
    )
  }
}
export default Footer;