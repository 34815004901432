import React, { Component } from 'react';
import { Accordion, Row, Col } from 'react-bootstrap';
import Card from '../../components/card/Card';

class Main extends Component {
  render(){
    return(
      <Row>
        <Col lg="{span: 10, offset:2}">
          <Accordion defaultActiveKey="0" flush>
            <Card title="Perfil Profesional" className="profile" eventKey="0">
              <p>Soy titulado como Licenciado en Artes con mención en Sonido, y he trabajado por más de 10 años en archivos, en tratamiento de registro digitales de audio en Radio Cooperativa, principalmente. Me he acercado a la programación para desarrollar herramientas que mejoren nuestro flujo de trabajo. Tengo conocimientos en Java, JavaScript, React y MongoDB; además, estoy interesado en el área de ciencia de datos y aprendizaje automático, por mis conocimientos de matemáticas y estadística.</p>
            </Card>
            <Card title="Habilidades" className="skills" eventKey="1">
              <section>
                <h5>Habilidades Digitales</h5>
                <ul>
                  <li>Búsqueda en Google</li>
                  <li>Usuario intermedio de Microsoft Windows</li>
                  <li>Usuario intermedio de Microsoft Excel y similares</li>
                  <li>Usuario básico de Ubuntu</li>
                </ul>
              </section>
              <section>
                <h5>Idiomas</h5>
                <ul>
                  <li>Español: nativo</li>
                  <li>Inglés: Lectura y Conversación</li>
                </ul>
              </section>
              <section>
                <h5>Programación y Desarrollo Web</h5>
                <ul>
                  <li>Java</li>
                  <li>MongoDB</li>
                  <li>JavaScript</li>
                  <li>HTML5</li>
                  <li>CSS3</li>
                  <li>MySQL</li>
                </ul>
              </section>
            </Card>
            <Card title="Sectores de Experiencia" className="industries" eventKey="2">
              <ul> 
                <li>Conservación y manejo de Archivos Sonoros</li>
                <li>Desarrollo Web</li>
                <li>Docencia</li>
              </ul>
            </Card>
            <Card title="Trayectoria Profesional" className="works" eventKey="3">
              <section>
                <h5>Radio Cooperativa</h5>
                <span><b>Asistente de Archivo</b> | febrero de 2013 - actualidad</span>
                <p>El trabajo realizado se centra en la recoleccion de fragmentos de audio y referencias para la producción de notas y reportajes.</p>
                <p>Para ellos rastreamos nuestro catálogo y usamos herramientas web como búsquedas avanzadas en Google, extrayendo información relevante del hecho que está en el centro de la noticia. </p>
              </section>
              <section>
                <h5>Las Loicas - CricketChile</h5>
                <span><b>Desarrollador de Sitio Web</b> | julio de 2018 - octubre de 2019</span>
                <p>Desarrollo y mantención del sitio web de la selección chilena de cricket femenino, Las Loicas. <a href="https://fip3torrejon.github.io/loicas">[+]</a></p>
              </section>
              <section>
                <h5>Instituto Profesional AIEP</h5>
                <span><b>Profesor de Ciencias Básicas</b> | marzo de 2012 - enero de 2015</span>
                <p>Profesor de Matemáticas, Física, Cálculo y Estadísticas, para la carrera de Prevención de Riesgos, de las sedes Providencia y Bellavista.</p>
              </section>
            </Card>
            <Card title="Historia Académica" className="academy" eventKey="4">
              <section>
                <h5>Universidad de Chile</h5>
                <p>
                  <span><b>Licenciatura en Artes con mención en Sonido</b><br/></span>
                  <span>2005-2010</span>
                </p>
              </section>
              <section>
                <h5>Duoc UC</h5>
                <p>
                  <span><b>Diploma Analista Desarrollador de Aplicaciones de Software Java</b><br/></span>
                  <span>2017-2018</span>
                </p>
              </section>
              <section>
                <h5>Instituto Chileno Norteamericano</h5>
                <p>
                  <span><b>Inglés comunicativo para la interacción personal en el trabajo</b><br/></span>
                  <span>2014</span>
                </p>
                <p>
                  <span><b>Inglés intermedio para facilitar la socialización en la empresa</b><br/></span>
                  <span>2015</span>
                </p>
              </section>
            </Card>
            <Card title="Otros Proyectos" className="projects" eventKey="5">
              <section>
                <h5>Sagitta Producciones</h5>
                <span><b>Director de Sonido y Música</b> | noviembre de 2019 - actualidad</span>
                <p>Encargado del diseño sonoro y la musicalización del cortometraje "Fue al atardecer" </p>
              </section>
            </Card>
            <Card title="Intereses y hobbies" className="hobbies" eventKey="6">
              <p>Me interesa mucho la música, soy aficionado a escucharla en grandes cantidades y estoy incursionando en la composición. También me atrae mucho el deporte y el arte en general, aunque soy más un consumidor que un practicante. Donde mejor me desenvuelvo es en el ámbito de las matemáticas y la programación. Mi actividad favorita es la cocina.
              </p>
            </Card>
          </Accordion>
        </Col>
      </Row>
    )
  }
}
export default Main;