import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import Navbar from '../../components/navbar/Navbar';
import Logo from '../../components/logo/Logo';
import styles from './Header.module.css'

class Header extends Component {
  render(){
    return(
      <Row className={styles.header}>
        <Col md={4} className="logo">
          <Logo/>
        </Col>
        <Col md={8} className="navbar">
          <Navbar />
        </Col>
      </Row>
    )
  }
}
export default Header;