import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import styles from './Navbar.module.css';

class Navbar extends Component {
  render(){
    return(
      <Row>
        <Col className={styles.navTitle}>
          <Row>
            <Col className={styles.jobTitle}>ESPECIALISTA EN ARCHIVOS</Col>
          </Row>
          <Row>
            <Col className={styles.jobTitle}>ANALISTA PROGRAMADOR</Col>
          </Row>
          <Row>
            <Col className={styles.name}>Felipe Torrejón Traslaviña</Col>
          </Row>
        </Col>
      </Row>
    )
  }
}
export default Navbar;