import React, { Component } from 'react';
import Accordion from 'react-bootstrap/Accordion';

class Card extends Component {
  render(){
    return(
      <Accordion.Item eventKey={this.props.eventKey}>
        <Accordion.Header>{this.props.title}</Accordion.Header>
        <Accordion.Body>{this.props.children}</Accordion.Body>
      </Accordion.Item>
    )
  }
}

export default Card;