import React from 'react';
import Image from 'react-bootstrap/Image';
import logo from '../../assets/logo_720.png';
import styles from './Logo.module.css';

function Logo(props) {
  return (
    <Image className={styles.logo} src={logo} alt="Logo" fluid></Image>
  )
}

export default Logo;