import React from 'react';
import Container from 'react-bootstrap/Container'
import Header from './layout/top/Header';
import Main from './layout/main/Main';
import Footer from './layout/bottom/Footer';
import 'bootstrap/dist/css/bootstrap.min.css'
import './App.module.css'
import style from './App.module.css'

function App() {
  return (
    <Container>
      <Header/>
      <Main/>
      <Footer/>
    </Container>  
  );
}

export default App;
